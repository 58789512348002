import 'babel-polyfill'
import 'react-app-polyfill/ie11'
import React, { Component } from 'react'
import Layout from '../../components/layout'
import { Container, UncontrolledAlert  } from 'reactstrap'
import ForumContent from '../../containers/forum'
import Breadcrumb from '../../components/breadcrumb';
import { Banner } from '../../components/banner';


export default class forum extends Component {
    constructor(props) {
        super(props)

        let verified = false
        let notification = false
        let danger = false
        let dangerMessage = ""
        
        if(!this.props.location.state){
            verified = false
            notification = false
        } else {
            verified = this.props.location.state.unverified ? true : false
            notification = this.props.location.state.notification ? true : false
            danger = this.props.location.state.danger ? true : false
            dangerMessage = this.props.location.state.dangerMessage ? this.props.location.state.dangerMessage : ""
        } 

        this.state = {
            verified : verified,
            notification : notification,
            danger : danger,
            dangerMessage : dangerMessage
        }
    }

    render() {
        return (
            <ForumLayout location={this.props.location} fluid={true}>
                {this.state.verified ? (
                    <UncontrolledAlert color="warning" style={{marginLeft: `2rem`, marginRight: `1rem`}}>
                        You must be logged in to access this page
                    </UncontrolledAlert> )
                : null }
                {this.state.notification ? (
                    <UncontrolledAlert color="success" style={{marginLeft: `2rem`, marginRight: `1rem`}}>
                        Your forum post has been successfully submitted!
                    </UncontrolledAlert> )
                : null }
                {this.state.danger ? (
                    <UncontrolledAlert color="danger" style={{marginLeft: `2rem`, marginRight: `1rem`}}>
                        {this.state.dangerMessage}
                    </UncontrolledAlert> )
                : null }
                <ForumContent active={this.props.location.hash} />
            </ForumLayout>
        )
    }
}


export const ForumLayout = props => {
    return(
        <Layout>
            <Banner title={`Forum`} />
            {props.location ? (<Breadcrumb loc={props.location}/>) : undefined}
            <Container
                fluid={props.fluid ? true : undefined}
                style={{
                    padding: '2rem 4rem 1rem',
                    minHeight: '75vh'
            }}>
                {props.children}

            </Container>
        </Layout>
    )
}