import 'babel-polyfill'
import 'react-app-polyfill/ie11'
import React, { Component } from 'react'
import { Collapse,
  Container,
  Navbar,
  NavbarToggler,
  Button,
  Nav,
  NavItem,
  NavLink,
  Input,
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
  ListGroupItemText,
  Pagination, PaginationItem, PaginationLink, Row, Col} from 'reactstrap'
import { navigate } from '@reach/router';
import { handleGetForumPosts, handleGetForumTags, handleGetForumCategories} from '../services/api'
import { BarLoader } from 'react-spinners';
import { isLoggedIn } from '../services/auth';
import { MdAdd } from 'react-icons/md'


const override = `
    margin: 0 auto;
`



export default class forumContent extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this)
    this.state = {
      isOpen: false,
      isActive : this.props.active,
      search : "",
      isLoading: false,
      categories: []
    };
  }

  componentDidMount = async () => {
    this.setState({
      isLoading: true
    })
    let res = await handleGetForumPosts()
    console.log(res);
    let tag = await handleGetForumTags()
    let categories = await handleGetForumCategories()

    this.setState({
        data : res,
        tags : tag,
        categories: categories
    }, () => {
      this.setState({
        isLoading: false
      })
    })

  }

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  handleChange = (data) => {
    this.setState({
      isActive : data,
      search : ""
    })
  }

  handleSearch = event =>{
    this.setState({
      search : event.target.value
    })
  }

  renderCategories(){
    return this.state.categories.map((value,index) => {
      let hash = '#'+value.slug;
      return (
        <NavItem key={index}>
          <NavLink active={this.state.isActive === hash ? true : undefined}  href={hash} onClick={() => this.handleChange(hash)}>
            {value.category}
          </NavLink>
        </NavItem>
      )
    });
  }

  render() {
    return (
      <div>
        <Navbar expand="md" light>
          <NavbarToggler onClick={this.toggle} className="mr-auto"/>
            <Collapse isOpen={this.state.isOpen} navbar>
              <Nav pills>
                <NavItem>
                  <NavLink active={this.state.isActive === '#all' ? true : undefined} href="#all" onClick={() => this.handleChange("#all")}>
                    All Posts
                  </NavLink>
                </NavItem>
                {this.renderCategories()}
                <NavItem>
                  <NavLink active={this.state.isActive === '#tags' ? true : undefined} href="#tags" onClick={() => this.handleChange("#tags")}>
                    Tags
                  </NavLink>
                </NavItem>
                &nbsp;
                <NavItem className="d-md-none" >
                  {isLoggedIn() ? (
                    <ComposeButton />
                  ): null}
                </NavItem>
                &nbsp;

              </Nav>
            </Collapse>
          <NavItem className="d-none d-md-block" tag="span">
            {isLoggedIn() ? (
              <ComposeButton />
            ): null}
          </NavItem>
          &nbsp;
          <NavItem className="ml-auto d-none d-sm-block"  tag="span">
            <Input
              placeholder="Search keyword..."
              type="text"
              value={this.state.search}
              onChange={this.handleSearch}
            />
          </NavItem>
        </Navbar>
        <Container fluid style={{padding:`2rem 1rem`}}>
          <Content activity={this.state.isActive} filter={this.state.search} data={this.state.data} tags={this.state.tags} state={this.state.isLoading}/>
        </Container>
      </div>
    )
  }
}

// ITEM LOOP
class Content extends Component {
  constructor(props){
    super(props)

    this.pageSize = 10

    this.state = {
      currentPage: 0,
      pageSize : this.pageSize,
      pagesCount : 1,
      selectedTag : "",
      selectedTagData : null,
    }
  }
  
  handleClick(e, index) {
   
    e.preventDefault();

    this.setState({
      currentPage: index
    });
    
  }

  handleSelectTag(tag){
    
    let {data} = this.props
    let selectedTags = []

    data.forEach(element => {
      if(JSON.parse(element.tag).includes(tag)){
        selectedTags.push(element)
      }
    });

    this.setState({
      selectedTag : tag,
      selectedTagData : selectedTags
    })
  }

  render(){
    return(
      <div>
        {(this.props.state || !this.props.data) ? (
          <div className="text-center" style={{margin:`auto`, padding:`1.5rem 0`}}>
            {`Loading Forum Posts...`}
            <BarLoader
              sizeUnit={"px"}
              width={200}
              color={'#123abc'}
              css={override}
            />
        </div>
        // Different logic for TAGS
        ) : (this.props.activity === '#tags') ? (
          <div>
            {/* Loading state */}
            {this.props.state ? (
                <div className="text-center" style={{margin:`auto`, padding:`1.5rem 0`}}>
                    {`Loading Forum Tags...`}
                    <BarLoader
                      sizeUnit={"px"}
                      width={200}
                      color={'#123abc'}
                    />
                </div>
            // Check if there are tags found
            ) : (!this.props.tags || this.props.tags.length === 0) ?(
              'No Forum Tags found'
            ) : (
              // Check if there is a selected tag and filter the posts
              !this.state.selectedTagData ? (
                /* loop through all found tags */
                <Row>
                  <Col style={{ marginLeft : `1.45rem` }}>
                    {this.props.tags.map(function(value, index){
                      return(
                        <Button
                        style={{margin:`0px 3px 10px`}}
                        color="info"
                        key={index}
                        onClick={(event) => {
                          event.preventDefault()
                          this.handleSelectTag(value.tag_name)
                        }}
                        >
                          {value.tag_name}
                        </Button> 
                      )
                    }, this)}
                  </Col>
                </Row>
              ) : (
                <div>
                  <Row>
                    <Col style={{ marginLeft : `1.45rem`, marginBottom : `0.5rem` }} className="clearfix">
                      {this.props.tags.map(function(value, index){
                        return(
                          <Button
                            style={{margin:`0px 3px 10px`}}
                            color="info"
                            key={index}
                            onClick={(event) => {
                              event.preventDefault()
                              this.handleSelectTag(value.tag_name)
                            }}
                          >
                            {value.tag_name}
                          </Button> 
                        )
                      }, this)}
                    </Col>
                  </Row>
                  <ListGroup>
                    <p>Post(s) tagged : "{this.state.selectedTag}" ( {this.state.selectedTagData.length} found )</p>
                    <LoopItem items={this.state.selectedTagData} type="#all" filter={this.props.filter} state={this.props.state}/>
                  </ListGroup>
                {/* REMOVED PAGINATION */}
                  {/* <Pagination>
                    <PaginationItem disabled={this.state.currentPage <= 0}>
                      <PaginationLink
                        onClick={e => this.handleClick(e, this.state.currentPage - 1)}
                        previous
                        href="#"
                      >
                        &lt;&lt; Newer Posts
                      </PaginationLink>
                    </PaginationItem>

                    {[...Array( Math.ceil(this.state.selectedTagData.length / this.pageSize))].map((page, i) => 
                      <PaginationItem active={i === this.state.currentPage} key={i}>
                        <PaginationLink onClick={e => this.handleClick(e, i)} href="#">
                          {i + 1}
                        </PaginationLink>
                      </PaginationItem>
                    )}

                    <PaginationItem disabled={this.state.currentPage >=  Math.ceil(this.state.selectedTagData.length / this.pageSize) - 1}>
                      <PaginationLink
                        onClick={e => this.handleClick(e, this.state.currentPage + 1)}
                        next
                        href="#"
                      >
                        Older Posts &gt;&gt;
                      </PaginationLink>
                    </PaginationItem>
                  </Pagination> */}
                </div>
              )
            )}
            </div>       
        ) : (
          // Paginated Post List
          <div>
            <ListGroup>
              <LoopItem items={this.props.data.slice(this.state.currentPage * this.state.pageSize,(this.state.currentPage + 1) * this.state.pageSize)} type={this.props.activity} filter={this.props.filter} state={this.props.state}/>
            </ListGroup>
            <Pagination>
              <PaginationItem disabled={this.state.currentPage <= 0}>
                <PaginationLink
                  onClick={e => this.handleClick(e, this.state.currentPage - 1)}
                  previous
                  href="#"
                >
                  &lt;&lt; Newer Posts
                </PaginationLink>
              </PaginationItem>

              {[...Array( Math.ceil(this.props.data.length / this.pageSize))].map((page, i) => 
                <PaginationItem active={i === this.state.currentPage} key={i}>
                  <PaginationLink onClick={e => this.handleClick(e, i)} href="#">
                    {i + 1}
                  </PaginationLink>
                </PaginationItem>
              )}

              <PaginationItem disabled={this.state.currentPage >=  Math.ceil(this.props.data.length / this.pageSize) - 1}>
                <PaginationLink
                  onClick={e => this.handleClick(e, this.state.currentPage + 1)}
                  next
                  href="#"
                >
                  Older Posts &gt;&gt;
                </PaginationLink>
              </PaginationItem>
            </Pagination>
          </div>
        )}
      </div>
    )
  }
}

// DATA MAPPING & FILTERING
const LoopItem = (props) => {
  let postCnt = 0;
  if(props.state){
    return (
      <div className="text-center" style={{margin:`auto`, padding:`1.5rem 0`}}>
          {`Loading Forum Posts...`}
          <BarLoader
            sizeUnit={"px"}
            width={200}
            color={'#123abc'}
          />
      </div>
    )
  } else {
    if(!props.items || props.items.length === 0){
      return 'No Forum Post found'
    } else {
      let postQty = props.items.length
      return props.items.map(function(value, index){
        // CHECK FOR TEXT SEARCH
        if(props.filter === ''){
          // CHECK IF POST IS SAME CATEGORY
          if (props.type === '' || props.type === '#all'){
            postCnt += 1
            return (
              <ListGroupItem key={index}>
                <ListGroupItemHeading tag="a"
                  href="#"
                  onClick={
                    event => {
                      event.preventDefault()
                      window.location.replace('view/?id=' + props.items[index].post_id)
                      }
                  }>
                  {value.title}
                </ListGroupItemHeading>
                <ListGroupItemText className="clearfix" tag="div">
                  <font className="float-left">
                    Posted by : {value.author_name}
                  </font>
                  <font className="float-right">
                    Date Posted : {(new Date(value.date_posted)).toLocaleString('en-US')}
                  </font>
                  <br />
                  <font className="float-right">
                    Latest Comment : {(new Date(value.date_modified)).toLocaleString('en-US')}
                  </font>
                </ListGroupItemText>
              </ListGroupItem>
            )
          } else {
            // SKIP IF POST IS NOT SAME CATEGORY
            if('#'+value.category_slug !== props.type || props.filter === -1){
              if(postCnt === 0 && postQty - 1 === index)
                return 'No Forum Post found for this category'
              return ''
            } else {
              postCnt += 1
              return (
                <ListGroupItem key={index}>
                <ListGroupItemHeading tag="a"
                  href="#"
                  onClick={
                    event => {
                      event.preventDefault()
                      window.location.replace('view/?id=' + props.items[index].post_id)
                      }
                  }>
                  {value.title}
                </ListGroupItemHeading>
                <ListGroupItemText className="clearfix" tag="div">
                  <font className="float-left">
                  Posted by : {value.author_name}
                  </font>
                  <font className="float-right">
                    Date Posted : {(new Date(value.date_posted)).toLocaleString('en-US')}
                  </font>
                  <br />
                  <font className="float-right">
                    Latest Comment : {(new Date(value.date_modified)).toLocaleString('en-US')}
                  </font>
                </ListGroupItemText>
              </ListGroupItem>
              )
            }
          }
        } else {
          // CHECK IF KEYWORD IS PRESENT
          // ADDED REGEXP FOR ADDING OF EXPRESSION MODIFIERS
          if(value.title.search(new RegExp(props.filter, "i")) !== - 1){
            if (props.type === '' || props.type === '#all'){
              postCnt += 1
              return (
                <ListGroupItem key={index}>
                <ListGroupItemHeading tag="a"
                  href="#"
                  onClick={
                    event => {
                      event.preventDefault()
                      navigate('view/?id=' + props.items[index].post_id)
                      }
                  }>
                  {value.title}
                </ListGroupItemHeading>
                <ListGroupItemText className="clearfix" tag="div">
                  <font className="float-left">
                    Posted by : {value.author_name}
                  </font>
                  <font className="float-right">
                    Date Posted : {(new Date(value.date_posted)).toLocaleString('en-US')}
                  </font>
                  <br />
                  <font className="float-right">
                    Latest Comment : {(new Date(value.date_modified)).toLocaleString('en-US')}
                  </font>
                </ListGroupItemText>
              </ListGroupItem>
              )
            } else {
              // SKIP IF POST IS NOT SAME CATEGORY
              if('#'+value.category !== props.type || props.filter === - 1){
                if(postCnt === 0 && postQty - 1 === index)
                  return 'No Forum Post found for this category'
                return ''
              } else {
                postCnt += 1
                return (
                  <ListGroupItem key={index}>
                  <ListGroupItemHeading tag="a"
                    href="#"
                    onClick={
                      event => {
                        event.preventDefault()
                        navigate('view/?id=' + props.items[index].post_id)
                      }
                    }>
                    {value.title}
                  </ListGroupItemHeading>
                  <ListGroupItemText className="clearfix" tag="div">
                    <font className="float-left">
                    Posted by : {value.author_name}
                    </font>
                  <font className="float-right">
                    Date Posted : {(new Date(value.date_posted)).toLocaleString('en-US')}
                  </font>
                  <br />
                  <font className="float-right">
                    Latest Comment : {(new Date(value.date_modified)).toLocaleString('en-US')}
                  </font>
                  </ListGroupItemText>
                </ListGroupItem>
                )
              }
            }
          } else {
            if(postCnt === 0 && postQty - 1 === index)
              return 'No Forum Post found for this category'
            return ''
          }
        }
      })
    }
  }

}

// DEPRECATED - REQUIRES STATE FOR THE TAG LOOPING
// const LoopTags = (props) => {
//   if(props.state){
//     return (
//       <div className="text-center" style={{margin:`auto`, padding:`1.5rem 0`}}>
//           {`Loading Forum Tags...`}
//           <BarLoader
//             sizeUnit={"px"}
//             width={200}
//             color={'#123abc'}
//           />
//       </div>
//     )
//   } if(!props.items || props.items.length === 0){
//     return 'No Forum Post found'
//     } else {
//       return props.items.map(function(value, index){
//         return (
//           <Button
//             style={{margin:`0 3px`}}
//             color="info"
//             key={index}
//             onClick={(event) => {
//               event.preventDefault()
//               alert(value.tag_id)
//             }}
//           >
//             {value.tag_name}&nbsp;
//           </Button>
//         )
//       })
//   }
// }

const ComposeButton = () => {
    return(
      <Button
        color="primary"
        title="Create New Post"
        onClick={event =>{
          event.preventDefault()
          navigate('post/')
        }}
      >
        <MdAdd/> Create New Post
      </Button>
    )
}